const TikTok = props => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12.5757" r="12" fill={props?.color || '#717171'} />
    <path
      d="M18 11.3868C16.8118 11.4002 15.7424 11.037 14.739 10.3644V15.086C14.739 17.1844 13.3396 18.987 11.3461 19.4578C8.62647 20.09 6.32931 18.0857 6.03886 15.6914C5.73521 13.2969 7.24022 11.1985 9.53743 10.7277C9.98627 10.6335 10.6464 10.6335 11.0161 10.7142V13.2431C10.9104 13.2162 10.8048 13.1894 10.6992 13.1759C9.80143 13.0145 8.93009 13.4718 8.57361 14.3058C8.21718 15.1398 8.45478 16.1083 9.18095 16.6733C9.78824 17.1576 10.4615 17.2248 11.148 16.9019C11.8346 16.5926 12.2043 16.0276 12.2834 15.2609C12.2967 15.1533 12.2967 15.0322 12.2967 14.9112V5.83123C12.2967 5.57568 12.2967 5.57568 12.5475 5.57568H14.5146C14.6598 5.57568 14.7126 5.60256 14.7258 5.77745C14.8314 7.33783 15.9932 8.66954 17.4851 8.87133C17.6435 8.89824 17.8152 8.91168 18 8.92509L18 11.3868Z"
      fill={props?.color === 'white' ? '#2A9A76' : '#FFF'}
    />
  </svg>
)
export default TikTok

// 全局留咨信息
import { makeVar } from '@apollo/client'
import {
  LeadsFormType,
  LeadsFormUnitProps,
  LeadsFormChannel,
  LeadsFormAction
} from '@/interface/leadsForm'
import { RentyAIProperty } from '@/interface/property'

interface LeadsFormDialogProps {
  type: LeadsFormType // 留资弹窗类型
  channel: LeadsFormChannel // 留资弹窗来源渠道
  action: LeadsFormAction // action
  visible?: boolean
  unit?: LeadsFormUnitProps // 留资弹窗来源渠道如果是unit，就需要传入
  property?: RentyAIProperty // 留资弹窗的property
}

export const leadsInfo = makeVar<LeadsFormDialogProps>({
  type: LeadsFormType.contactUs,
  channel: null,
  action: null,
  visible: false // 可选项，这个visible是特殊情况，仅用来设置最初版本的留资弹窗（最初版本是dialog 不是drawer）
})

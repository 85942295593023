import { feedbackInfo } from '@/state/feedback'
import { useReactiveVar } from '@apollo/client'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { statsigLog } from '@/lib/statsigAndGa'
import Close from '../icons/close'
import Experience from './experience'
import FreeResponse from './freeResponse'
import Thanks from './thanks'

interface IStepInfo {
  like?: boolean
  response?: string
  contact?: string
}
enum StepName {
  LIKE = 'like',
  RESPONSE = 'response',
  THANKS = 'thanks',
  FINISH = 'finish'
}
const Feedback = () => {
  const { visible } = useReactiveVar(feedbackInfo)
  const [stepInfo, setStepInfo] = useState<IStepInfo>({})
  const startTimesStampRef = useRef(+new Date())
  const handleClose = () => {
    feedbackInfo({ visible: false })
    statsigLog('feedback_close_without_submitting')
  }
  const currentStep = useMemo(() => {
    const { like, response } = stepInfo
    if (typeof like === 'boolean') {
      if (typeof response === 'string') {
        return StepName.THANKS
      }
      return StepName.RESPONSE
    }
    return StepName.LIKE
  }, [stepInfo])
  const SwitchFeedbackContent = useMemo(() => {
    switch (currentStep) {
      case StepName.LIKE:
        return (
          <Experience
            onChange={newLike => {
              statsigLog('feedback_submit', {
                yes_or_no: Number(newLike)
              })
              setStepInfo({ ...stepInfo, like: newLike })
            }}
          />
        )
      case StepName.RESPONSE:
        return (
          <FreeResponse
            status={stepInfo.like}
            onChange={newResponse => {
              statsigLog('feedback_submit', {
                free_response: newResponse,
                duration: +new Date() - startTimesStampRef.current
              })
              setStepInfo({ ...stepInfo, response: newResponse })
            }}
          />
        )
      case StepName.THANKS:
        return (
          <Thanks
            onChange={newContact => {
              statsigLog('feedback_submit', {
                contact: newContact
              })
              setStepInfo({ ...stepInfo, contact: newContact })
              feedbackInfo({ visible: false })
            }}
          />
        )
      default:
        return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])
  useEffect(() => {
    if (visible) {
      setStepInfo({})
    }
  }, [visible])
  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            boxShadow: 'none',
            width: 'fit-content',
            overflowY: 'auto',
            margin: '0',
            borderRadius: '24px'
          }
        }
      }}
    >
      <DialogTitle className="flex flex-row justify-end items-center p-0 pt-4 pr-4">
        <IconButton className="" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className="p-10 pt-0 m-0 w-full flex justify-center items-center flex-col">
        {SwitchFeedbackContent}
      </DialogContent>
    </Dialog>
  )
}
export default Feedback

import { Box, FormControlLabel, Radio, RadioGroup, TextField, Button } from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'
import RadioCheckedIcon from '@/components/icons/radioCheckedIcon'
import RadioIcon from '@/components/icons/radioIcon'

const yesRadioData = [
  { value: 'chat', label: 'The AI chat makes finding units easy' },
  { value: 'map', label: 'The map really helps in my search' },
  { value: 'other', label: 'Other' }
]
const noRadioData = [
  { value: 'female', label: 'It’s hard to find desirable units.' },
  { value: 'units', label: 'Search filters don’t meet my needs' },
  { value: 'other', label: 'Other' }
]

const CustomsRadio = props => (
  <Radio
    disableRipple
    color="default"
    checkedIcon={<RadioCheckedIcon />}
    icon={<RadioIcon />}
    className="pl-5 py-[18px]"
    {...props}
  />
)
interface IFreeResponseProps {
  status?: boolean
  onChange: (_response: string) => void
}
const FreeResponse: FC<IFreeResponseProps> = ({ status = true, onChange }) => {
  const [selected, setSelected] = useState<string>()
  const radioData = useMemo(() => (status ? yesRadioData : noRadioData), [status])
  const [inputMessage, setInputMessage] = useState('')
  useEffect(() => {
    setSelected(radioData[0].value)
  }, [radioData])
  const handleMessageChange = e => {
    setInputMessage(e.target.value)
  }
  const onReportFeedback = () => {
    onChange(
      selected === 'other'
        ? inputMessage
        : radioData.filter(({ value }) => selected === value)?.[0].label
    )
  }
  return (
    <>
      <Box className="text-[22px] font-semibold mt-5 mb-10">
        {status
          ? 'Great to hear! What do you enjoy the most?'
          : "We're sorry to hear that. What can we improve?"}
      </Box>
      <Box className="bg-[#F7F7F7] p-10 rounded-[20px] gap-10">
        <RadioGroup
          name="radio-buttons-group"
          className="flex flex-col gap-3"
          onChange={(_, value) => setSelected(value)}
        >
          {radioData.map(({ value, label }) => (
            <FormControlLabel
              label={label}
              key={value}
              value={value}
              control={
                selected === value && value === 'other' ? (
                  <Box className="flex flex-col items-start transition-all">
                    <CustomsRadio checked={selected === value} />
                    <TextField
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none'
                        },
                        '& .MuiOutlinedInput-root': {
                          padding: '8px 12px'
                        },
                        '& .MuiOutlinedInput-input': {
                          fontSize: 16,
                          padding: '0'
                        }
                      }}
                      placeholder="Your feedback is important for us"
                      className="w-[352px] h-[66px] rounded-lg border-none font-averta bg-[#F7F7F7] ml-5 mb-5"
                      value={inputMessage}
                      onChange={handleMessageChange}
                    />
                  </Box>
                ) : (
                  <CustomsRadio checked={selected === value} />
                )
              }
              className="w-[390px] min-h-[56px] border bg-white rounded-xl overflow-hidden relative"
              sx={{
                borderColor: selected === value ? '#001229' : '#E9E9E9',
                '&.MuiFormControlLabel-root': {
                  margin: '0 !important'
                },
                '& .MuiFormControlLabel-label': {
                  position: 'absolute',
                  top: '0',
                  left: '52px',
                  lineHeight: '54px'
                }
              }}
            />
          ))}
        </RadioGroup>
        <Button
          className="w-full mx-auto bg-[#004D34] font-averta text-white px-[60px] py-3 mt-10 rounded-xl normal-case font-semibold text-base"
          onClick={onReportFeedback}
        >
          Report Feedback
        </Button>
      </Box>
    </>
  )
}
export default FreeResponse

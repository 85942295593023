import { Box, Button } from '@mui/material'
import { FC } from 'react'

interface IExperienceProps {
  onChange: (boolean) => void
}
const Experience: FC<IExperienceProps> = ({ onChange }) => (
  <>
    <Box className="text-[22px] font-semibold mt-5 mb-10">Do you like this website experience?</Box>
    <Box className="bg-[#F7F7F7] p-10 rounded-[20px] flex gap-8 box-border">
      <Button
        className="bg-white py-2 rounded-xl w-[174px] h-[70px] capitalize border-gray-200 hover:border-[#001229] border border-solid"
        onClick={() => onChange(true)}
      >
        <Box component="span" className="text-[32px] mr-2">
          🥰
        </Box>
        Yes
      </Button>
      <Button
        className="bg-white py-2 rounded-xl w-[174px] h-[70px] capitalize border-gray-200 hover:border-[#001229] border border-solid"
        onClick={() => onChange(false)}
      >
        <Box component="span" className="text-[32px] mr-2">
          🙁
        </Box>
        No
      </Button>
    </Box>
  </>
)
export default Experience

// 全局feedback
import { makeVar } from '@apollo/client'

interface FeedbackDialogProps {
  visible?: boolean
}

export const feedbackInfo = makeVar<FeedbackDialogProps>({
  visible: false
})

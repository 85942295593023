export default function radioCheckedIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      {...props}
    >
      <circle cx="8.5" cy="8" r="7.5" stroke="#001229" />
      <circle cx="8.5" cy="8" r="4" fill="#004D34" />
    </svg>
  )
}

// 留资来源渠道是unit的话 就需要传入unit的相关信息
export type LeadsFormUnitProps = {
  unitId?: string | null // unit id
  unitName?: string | null // unit name
  bedRoomId?: number | null // room id
  price?: number | string | null // 没有price 就是当前unit的price，如果有price模块，传当前选中的 moveInDate 的 price
  bedRoomNumber?: number | string | null // 当前unit的 bed
  bathRoomNumber?: number | string | null // 当前unit的 bath
  availableStartTime?: number | null // 当前unit的 availableStartTime
  leaseTerm?: number | null // 如果有price模块，传当前选中 的 lease term的值
  moveInDateEnd?: string | null // 如果有price模块，传当前选中的 moveInDate
  moveInDateStart?: string | null // 如果有price模块，传当前选中的 moveOutDate
  sqft?: string | null // 当前unit/floorPlan的面积
  modeType?: number | null // unit数据是unit还是floorPlan
}
// 留资来源渠道是room的话 就需要传入room的相关信息
export type LeadsFormRoomProps = {
  id?: string | null // room id
  name?: string | null // room name
  bedRoomId?: number | null // room id
  price?: number | string | null // 没有price 就是当前room的price，如果有price模块，传当前选中的 moveInDate 的 price
  bedRoomNumber?: number | string | null // 当前room的 bed
  bathRoomNumber?: number | string | null // 当前room的 bath
  availableStartTime?: number | null // 当前room的 availableStartTime
  leaseTerm?: number | null // 如果有price模块，传当前选中 的 lease term的值
  moveInDateEnd?: string | null // 如果有price模块，传当前选中的 moveInDate
  moveInDateStart?: string | null // 如果有price模块，传当前选中的 moveOutDate
  sqft?: string | null // 当前room/floorPlan的面积
}

// 留资弹窗类型
export enum LeadsFormType {
  contactUs = 'Contact Us',
  applyNow = 'Apply Now',
  requestTour = 'Request Tour'
}

// 留资弹窗触发方式
export enum LeadsFormChannel {
  room = 'room', // 聊到room的时候触发
  unit = 'unit', // 聊到unit的时候触发
  propertyDetail = 'property' // 聊到property detail的时候触发
}

// "contact us" 这种类型的留资弹窗可能会有以下几种方式
export enum ContactUsActionType {
  websiteLeaveMessage = 'renty_leave_message',
  rentyContactUs = 'renty_contact_us'
}

// "apply now" 这种类型的留资弹窗可能会有以下几种
export enum ApplyNowActionType {
  unknown = 'unknown' // 默认 填充字段 没用
}

// "request tour" 这种类型的留资弹窗可能会有以下几种
export enum RequestTourActionType {
  unknown = 'unknown'
}

export type LeadsFormAction = ContactUsActionType | ApplyNowActionType | RequestTourActionType

import { userInfoCatch } from '@/lib/catch'
import { useReactiveVar } from '@apollo/client'
import { Box, Button, InputAdornment, InputBase } from '@mui/material'
import { useState } from 'react'

const Thanks = ({ onChange }) => {
  const { email } = useReactiveVar(userInfoCatch)
  const [inputMessage, setInputMessage] = useState(email || '')
  const handleMessageChange = () => {
    onChange(inputMessage)
  }
  return (
    <>
      <Box className="text-[32px] font-bold my-5 text-center">
        <Box className="text-[48px] mb-5">🥂</Box>Thank you!
      </Box>
      <Box className="text-secondary text-center mb-5">
        We appreciate you taking the time to respond. Your insights are important to us. Please
        provide your contact information so that we can better serve you.
      </Box>
      <Box className="w-[420px] rounded-xl border border-[#DDD] flex justify-between items-center py-2 pl-5 pr-2">
        <InputBase
          placeholder="Enter your email or phone"
          className="w-full h-[48px] rounded-lg border-none"
          value={inputMessage}
          onChange={e => setInputMessage(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <Button
                className="mx-auto bg-[#004D34] font-averta text-white px-7 py-3 rounded-xl normal-case font-semibold text-base"
                onClick={handleMessageChange}
              >
                Submit
              </Button>
            </InputAdornment>
          }
        />
      </Box>
    </>
  )
}
export default Thanks

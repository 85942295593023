import { loginDialogOpen, shareConfig } from '@/lib/catch'
import { leadsInfo } from '@/state/leadsInfo'
import { useReactiveVar } from '@apollo/client'
import { useMediaQuery } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { feedbackInfo } from '@/state/feedback'
import { statsigLog } from '@/lib/statsigAndGa'

// 在sessionStorage保存用户本次打开页面的时间，使得每次用户重新打开页面，都可以重新计算时间
const USER_STAYED_START_TIME = 'user_stayed_start_time'
// 在sessionStorage保存用户本次在页面停留的时间，使得每次用户切换页面后返回当前页面，都可以继续计算时间
const CURRENT_SESSION_HAD_STAYED_TIME = 'current_session_has_stayed_time'
// 在localStorage保存半年内是否展示过feedback，如果展示过，则不会再弹
const HAD_SHOW_FEEDBACK = 'had_show_feedback_in_the_half_year'
// 使用者停留多少时长之后，弹出feedback
const STAYED_TIME = 1000 * 60 * 5
// 半年内是否已经弹过
const hadShow = () => !!localStorage.getItem(HAD_SHOW_FEEDBACK)

type IUseFeedbackTimeMonitor = () => void

/**
 * 在使用者在页面停留5分钟之后，弹出feedback。页面切换、隐藏时不计停留时长。
 */
const useFeedbackTimeMonitor: IUseFeedbackTimeMonitor = () => {
  const [isPC, setIsPC] = useState(false)
  const [startTime, setStartTime] = useState(+new Date())

  const stayTimeRef = useRef(0)
  const [showTime, setShowTime] = useState(false)
  // eslint-disable-next-line no-undef
  const timeHandle = useRef<NodeJS.Timeout>()

  const currentLeadsInfo = useReactiveVar(leadsInfo)
  const currentLoginDialog = useReactiveVar(loginDialogOpen)
  const currentFeedbackInfo = useReactiveVar(feedbackInfo)
  const currentShare = useReactiveVar(shareConfig)
  const matches = useMediaQuery('@media (min-width: 768px)')
  useEffect(() => {
    setIsPC(matches)
  }, [matches])

  useEffect(() => {
    // 进入页面，先判断需不需要定时弹窗。
    if (!hadShow()) {
      // 页面多处组件使用hooks时不重复种时间戳，及刷新页面不更新。
      if (!sessionStorage.getItem(USER_STAYED_START_TIME)) {
        // 如果没有时间戳，先种上时间戳
        sessionStorage.setItem(USER_STAYED_START_TIME, JSON.stringify(startTime))
        // 然后开始倒计时5mins
        return timeMonitor(STAYED_TIME)
      }

      // 如果已有sessionStorage保存的开始时间，
      // 那么可能是【当前页面切换新页面 / 刷新页面】后，
      // 此时sessionStorage还在，但是定时器已经不在了···
      // 需要根据sessionStorage里的停留时间，重新设置timeMonitor
      const sessionStayedTime = sessionStorage.getItem(CURRENT_SESSION_HAD_STAYED_TIME)
        ? JSON.parse(sessionStorage.getItem(CURRENT_SESSION_HAD_STAYED_TIME))
        : 0
      stayTimeRef.current = sessionStayedTime
      return timeMonitor(STAYED_TIME - sessionStayedTime)
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * 可配置等待时长的业务setTimeout
   */
  const setTimeout2CallFeedback = wait =>
    setTimeout(() => {
      setShowTime(true)
    }, wait)

  /**
   * 这个倒计时，需要对倒计时进行控制，比如，切换标签、页面缩小时，计时暂停
   * 关闭页面时，需要移除计时器
   */
  const timeMonitor = waitTime => {
    // 初始设置 5 mins
    timeHandle.current = setTimeout2CallFeedback(waitTime)
    const visibleEventCallback = () => {
      if (document?.hidden) {
        // 页面被遮挡或切换到其他标签页
        // 取消定时任务，更新已停留时长
        const newStayTime = stayTimeRef.current + +new Date() - startTime
        clearTimeout(timeHandle.current)
        sessionStorage.setItem(CURRENT_SESSION_HAD_STAYED_TIME, JSON.stringify(newStayTime))
      } else {
        // 页面重新可见
        // 重新设定定时任务
        // 更新本次startTime时间戳
        const newStayTime = JSON.parse(sessionStorage.getItem(CURRENT_SESSION_HAD_STAYED_TIME))
        timeHandle.current = setTimeout2CallFeedback(STAYED_TIME - newStayTime)
        stayTimeRef.current = newStayTime
        setStartTime(+new Date())
      }
    }
    const pageRefreshOrOpenNewPageInCurrentPage = () => {
      // 页面刷新前，保存当前session，用户已停留时长
      sessionStorage.setItem(
        CURRENT_SESSION_HAD_STAYED_TIME,
        JSON.stringify(stayTimeRef.current + +new Date() - startTime)
      )
    }
    document?.addEventListener('visibilitychange', visibleEventCallback)
    window?.addEventListener('beforeunload', pageRefreshOrOpenNewPageInCurrentPage)
    return () => {
      document?.removeEventListener('visibilitychange', visibleEventCallback)
      window?.removeEventListener('beforeunload', pageRefreshOrOpenNewPageInCurrentPage)
      // 关闭页面时，需要移除计时器
      clearTimeout(timeHandle.current)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const canShow =
        isPC &&
        !hadShow() &&
        !currentLeadsInfo.visible &&
        !currentFeedbackInfo.visible &&
        !currentLoginDialog &&
        !currentShare.open &&
        showTime
      if (canShow) {
        localStorage.setItem(HAD_SHOW_FEEDBACK, 'true')
        statsigLog('feedback_popup_open', { entry_point: 'user had stayed five minutes' })
        feedbackInfo({ visible: true })
      }
    }, 1000)
  }, [currentLeadsInfo, currentFeedbackInfo, currentLoginDialog, currentShare, isPC, showTime])
}

export default useFeedbackTimeMonitor

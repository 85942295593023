export default function radioIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      {...props}
    >
      <circle cx="8.5" cy="8" r="7.5" fill="white" stroke="#717171" />
    </svg>
  )
}
